<template>

  <v-app id="inspire">
    <v-app id="inspire">
      <v-main>
        <v-container
          class="fill-height"
          fluid
        >
          <v-row
            align="center"
            justify="center"
          >
            <v-col
              cols="12"
              sm="8"
              md="4"
            >
              <v-card class="elevation-12">
                <v-toolbar
                  color="primary"
                  dark
                  flat
                >
                  <v-toolbar-title>Login</v-toolbar-title>
                  <v-spacer></v-spacer>
                </v-toolbar>
                <v-card-text>
                  <v-form>
                    <v-text-field
                      label="Login"
                      name="login"
                      prepend-icon="mdi-account"
                      type="text"
                      v-model="$store.state.user.email"
                      :rules="ruleEmail"
                    ></v-text-field>
  
                    <v-text-field
                      id="password"
                      label="Password"
                      name="password"
                      prepend-icon="mdi-lock"
                      type="password"
                      :rules="rulePassword"
                      v-model="$store.state.user.password"
                    ></v-text-field>
                  </v-form>
                </v-card-text>
                <v-card-actions>
                  <router-link to="/forgot-password">Esqueci minha senha</router-link>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" @click="login">Login</v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-main>
    </v-app>
  </v-app>

</template>

<script>
import {login} from '@/services/users'
  export default {
    data:()=>({
      teste: null,
      rulePassword: [true],
      ruleEmail: [true]
    }),
    methods: {
      login(){
        login({email: this.$store.state.user.email, password: this.$store.state.user.password})
        .then(() => {
          this.rulePassword = [true]
          this.ruleEmail = [true]
          this.$router.push('/')
          //window.location.href = '/'
        })
        .catch(err => {
          this.rulePassword = [err]
          this.ruleEmail = [false]
        })
      }
    },
    mounted(){
      localStorage.removeItem('token')
    }
  }
</script>

<style lang="scss" scoped>

</style>